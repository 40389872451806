import {
    IS_PICKING_UP_WHEN_READY,
    IS_WAITING_AT_DEALER,
} from '../transport-services/TransportServicesEdit';
import { StandardServicesData } from '../standard-services/useStandardServices';

// Custom transport services should not be viewed as booked services
export const nonBookableServiceIds = [
    IS_WAITING_AT_DEALER,
    IS_PICKING_UP_WHEN_READY,
];

type SelectedServiceType = StandardServicesData['selectedServices'][number];

export function isBookableService(service: SelectedServiceType) {
    return !nonBookableServiceIds.includes(service.serviceId);
}
