import { DetailText, InfoCard } from '@gnist/design-system';
import { DealerViewModel } from 'external-apis/src/types/port';
import { useGetServices } from '../../_api/http/services';
import { useGetVehicle } from '../../_api/http/vehicle';
import { QueryError } from '../../components/QueryError';
import { ServicesDone } from '../../components/services/ServicesDone';
import { useBookingStepAction } from '../../lib/datadog/hooks';
import { useLanguageContext } from '../../lib/languages/languageContext';
import { ChooseDealerData } from '../choose-dealer/ChooseDealerSection';
import { TransportServicesSingleCardLoader } from './TransportServicesLoader';
import { TransportServicesData } from './TransportServicesSection';
import { isBookableService } from '../shared/isBookableService';

interface TransportServicesDoneProps {
    data: TransportServicesData;
    regNr: string;
    selectedDealer: ChooseDealerData;
    dealers: DealerViewModel[] | undefined;
}

export function TransportServicesDone({
    data: { isWaiting, selectedServices },
    regNr,
    selectedDealer,
    dealers,
}: TransportServicesDoneProps) {
    const vehicle = useGetVehicle(regNr);

    const [lc] = useLanguageContext();
    const servicesResponse = useGetServices({
        vin: vehicle.data?.vin,
        dealerNumbers: [selectedDealer.dealerNumber],
    });

    const additionalActions = [];

    const dealer = dealers?.find((x) => x.id === selectedDealer.dealerNumber);
    if (dealer) {
        additionalActions.push(`Choose transport services ${dealer.name}`);
    }

    useBookingStepAction('Choose transport services', {
        additionalActions,
    });

    if (servicesResponse.isError || vehicle.isError) {
        return (
            <QueryError
                error={servicesResponse.error || vehicle.error}
                isError
            />
        );
    }

    if (servicesResponse.isLoading) {
        return <TransportServicesSingleCardLoader />;
    }

    if (isWaiting) {
        return (
            <InfoCard density="compact">
                <DetailText $as="div">
                    {lc.transportServices.done_wait_at_dealer}
                </DetailText>
            </InfoCard>
        );
    }

    const bookableTransportServices =
        selectedServices.filter(isBookableService);

    return (
        <>
            {bookableTransportServices.length <= 0 && (
                <InfoCard density="compact">
                    <DetailText $as="div">
                        {lc.transportServices.no_services_chosen}
                    </DetailText>
                </InfoCard>
            )}
            {bookableTransportServices.length > 0 && dealer && (
                <ServicesDone
                    selectedServices={bookableTransportServices}
                    selectedDealerNumber={dealer.id}
                />
            )}
        </>
    );
}
