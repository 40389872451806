import { useQuery } from '@tanstack/react-query';
import { useFetchersContext } from 'external-apis/src/fetchers/fetchersProvider';

const useFetchVehicleHistory = () => {
    const [fetchers] = useFetchersContext();
    return fetchers.bilhold.fetcher
        .path('/v1/cars/{Vin}/maintenance-log')
        .method('get')
        .create();
};

export const useVehicleHistory = (vin?: string) => {
    const fetchHistory = useFetchVehicleHistory();
    return useQuery({
        queryKey: ['maintenance-log', vin],
        queryFn: async () => {
            if (!vin) return [];
            const response = await fetchHistory({ Vin: vin });
            return response.data;
        },
        enabled: !!vin,
    });
};
