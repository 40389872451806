import { useQuery } from '@tanstack/react-query';
import { useFetchersContext } from 'external-apis/src/fetchers/fetchersProvider';

const useFetchDealers = () => {
    const [fetchers] = useFetchersContext();
    return fetchers.bilhold.fetcher.path('/v1/dealers').method('get').create();
};

export const useDealers = () => {
    const fetchDealers = useFetchDealers();
    return useQuery({
        queryKey: ['dealers'],
        queryFn: async () => {
            const response = await fetchDealers({});
            return response.data;
        },
    });
};
