import { useNavigate, useParams } from 'react-router-dom';
import { useCarInformation } from 'src/features/home/queries/useCarInformation';
import { themeOverride } from 'src/utils/dev-tools/devToolsUtils';
import { ChildThemeProvider, getThemeFromBrand } from 'theming';
import { PageLayout } from '../components/PageLayout';
import {
    AlertBanner,
    Heading1Text,
    IconButton,
    InfoCard,
} from '@gnist/design-system';
import FeedbackPrompt from 'src/shared/feedback-card/FeedbackPrompt';
import { Timeline, TimelineIcon } from './Timeline';
import { useFlag } from 'feature-toggle';
import lang from 'src/utils/lang';
import { useVehicleHistory } from './useVehicleHistory';
import { useDealers } from './useDealers';

export function VehicleHistory({ path }: { path: string }) {
    const { vin } = useParams();
    const navigate = useNavigate();
    const vehicleHistory = useVehicleHistory(vin);
    const vehicleInformation = useCarInformation(vin);
    const dealers = useDealers();
    const isPending =
        vehicleHistory.isPending ||
        vehicleInformation.isPending ||
        dealers.isPending;
    const isError =
        vehicleHistory.isError || vehicleInformation.isError || dealers.isError;

    const shouldShowVehicleHistory = useFlag('ShouldShowServiceBooklet');
    if (!shouldShowVehicleHistory) return null;

    if (isError) {
        return (
            <AlertBanner
                type={'error'}
                action={{
                    label: lang.back_to_main_page,
                    onClick: () => navigate('/'),
                }}
                message={lang.somethingWentWrong}
            />
        );
    }

    if (isPending) return <InfoCard isLoading />;
    const { brand, model } = vehicleInformation.data;
    const theme = getThemeFromBrand(brand || '');

    return (
        <ChildThemeProvider theme={theme} overrideTheme={themeOverride}>
            <PageLayout>
                <IconButton
                    icon={'chevron_left'}
                    label={model}
                    showLabel="right"
                    onClick={() => navigate(-1)}
                />
                <Heading1Text>{lang.vehicle_service_heading}</Heading1Text>
                <Timeline
                    steps={vehicleHistory.data.map((history) =>
                        toTimelineStep(history, dealers.data)
                    )}
                />
                <FeedbackPrompt
                    customTitle={lang.feedbackTitle}
                    customContent={lang.feedbackContent}
                    path={path}
                />
            </PageLayout>
        </ChildThemeProvider>
    );
}

type VehicleHistoryEntry = NonNullable<
    ReturnType<typeof useVehicleHistory>['data']
>[number];
type VehicleHistoryEntryType = VehicleHistoryEntry['jobs'][number]['type'];
type Dealer = NonNullable<ReturnType<typeof useDealers>['data']>[number];

const getTimelineIcon = (type: VehicleHistoryEntryType): TimelineIcon => {
    switch (type) {
        case 'Service':
            return 'car';
        default:
            return 'build';
    }
};

const toTimelineStep = (data: VehicleHistoryEntry, dealers: Dealer[]) => {
    const dealerName = dealers.find(
        (x) => x.dealerNumber === data.dealerNumber
    )?.name;
    const tasks = data.jobs.map((job) => job.description);
    const icon = data.jobs.some((job) => job.type === 'Service')
        ? getTimelineIcon('Service')
        : getTimelineIcon('Other');
    const date = new Date(data.date);
    return {
        icon,
        content: {
            tasks,
            date,
            dealerName,
        },
    };
};
